import React from "react";
import Modal from "react-modal";
import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { connect } from "react-redux";
import TimeTableColumns from "./TimeTableColumn";
import TimeTableColumnLabel from "./TimeTableColumnLabel";
import ClassesSubjectRow from "./ClassesSubjectRow";
import Auth from "../../../services/Auth";
import * as ACTIONS from "../../../../features/timetable/actions";
import * as CACTIONS from "../../../../features/classes/actions";
import { sortClassesAlphabetically } from "../../../utils/assortments";
import { selectSettings } from "../../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";
import { api } from "../../../../services/api";

const mapStateToProps = (state) => ({
  schoolyear: selectCurrentSchoolyear(state),
  subjects: state.subjects,
  classes: sortClassesAlphabetically(state.classes),
  timetable: state.timetable,
  settings: selectSettings(state),
});

const mapDispatchToProps = {
  handleEntries: ACTIONS.handleEntries,
  clearAll: ACTIONS.clearAll,
  upsertTimetable: ACTIONS.upsertTimetable,
  upsertTimetableSaturday: ACTIONS.upsertTimetable,
  fetchTimetable: ACTIONS.fetchTimetable,
  updateClassListItem: CACTIONS.updateClassListItem,
  updateSettings: api.endpoints.updateSettings.initiate,
};

const ModalStyles = {
  overlay: {
    backgroundColor: "rgba(64, 184, 126, 0.7)",
  },
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
};
Modal.setAppElement("#appMountPoint");

const TimeTableDayLabels = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];

class AddTimetableForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalHoverClass: "",
      modalDay: "",
      modalHour: "",
      hour: "",
      day: null,
      timeTableLabelCnt: 7,
      preselect_class_id: false,
      preselect_subject_id: false,
      preselect_class_id_b: false,
      preselect_subject_id_b: false,
      schoolyear: props.schoolyear,
      ab_weeks: false,
      ab_weeks_change_state: false,
      ab_settings_edit: false,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addModalHoverClass = this.addModalHoverClass.bind(this);
    this.removeModalHoverClass = this.removeModalHoverClass.bind(this);
    this.addTimeTableLabel = this.addTimeTableLabel.bind(this);
    this.resetData = this.resetData.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.changeSaturday = this.changeSaturday.bind(this);
    this.entriesABWeeks = this.entriesABWeeks.bind(this);
    this.changeABSettings = this.changeABSettings.bind(this);
    this.changeABSettingsHandler = this.changeABSettingsHandler.bind(this);
    this.cleanUpABProperties = this.cleanUpABProperties.bind(this);
    this.setUpABProperties = this.setUpABProperties.bind(this);

    this.updateTimeTableItem = this.updateTimeTableItem.bind(this);
  }

  /**
   * fetch data if needed
   */
  componentDidMount() {
    let length = this.findMaxHourFromList();
    const hasABWeeksSettings = this.hasABWeeksSettings();
    if (length < 7) length = 7;
    this.setState({
      timeTableDefCnt: length,
      ab_weeks: hasABWeeksSettings,
      ab_weeks_change_state: hasABWeeksSettings,
    });

    if (this.props.fetchData) {
      this.props.fetchTimetable(Auth.getUserId(), this.state.schoolyear);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings) {
      this.setState({
        ab_weeks: this.hasABWeeksSettings(),
      });
    }
  }

  /**
   * bei aktivierung und bestehenden stundenplan alles
   */
  setUpABProperties() {
    const remapedTimetablesEntries = this.props.timetable.entries.map(
      (entry) => {
        const obj = {
          classId: entry.classId,
          subjectId: entry.subjectId,
        };
        return {
          day: entry.day,
          hour: entry.hour,
          day_name: entry.day_name,
          classId: entry.classId,
          subjectId: entry.subjectId,
          classes_ab_weeks: {
            A: obj,
            B: { ...obj, week_clone: true },
          },
        };
      },
    );
    const newTimetable = {
      ...this.props.timetable,
      entries: remapedTimetablesEntries,
    };
    this.props.upsertTimetable(newTimetable, this.state.schoolyear, false);
  }

  /**
   * new function for updating timetable items
   * @param item
   */
  updateTimeTableItem(item) {
    if (this.state.ab_weeks) {
      if (item.week_type === "B") {
        this.setState({
          preselect_class_id_b: item.class_id,
          preselect_subject_id_b: item.subject_id,
        });
      } else {
        this.setState({
          preselect_class_id: item.class_id,
          preselect_subject_id: item.subject_id,
        });
      }
    } else {
      this.setState({
        preselect_class_id: item.class_id,
        preselect_subject_id: item.subject_id,
      });
    }
    // check if delete "Leerstunde"
    const clearEntry = !item.class_id && !item.subject_id;

    const classId = clearEntry ? this.state.preselect_class_id : item.class_id;
    const subjectId = clearEntry
      ? this.state.preselect_subject_id
      : item.subject_id;

    const classEntry = this.props.classes.find((p) => p._id === classId);

    const subjectEntry = classEntry
      ? classEntry.subjects.find((s) => s._id === subjectId)
      : false;

    // get other timetables which ar not changed
    const timeTableEntriesFiltered = this.props.timetable.entries.filter(
      (entry) =>
        !(entry.day === this.state.day && entry.hour === this.state.hour),
    );

    const timeTableEntries = timeTableEntriesFiltered.map((entry) => {
      const aBWeekA =
        entry.classes_ab_weeks && entry.classes_ab_weeks.A
          ? {
              classId: entry.classes_ab_weeks.A.classId,
              subjectId: entry.classes_ab_weeks.A.subjectId,
            }
          : null;

      const aBWeekB =
        entry.classes_ab_weeks && entry.classes_ab_weeks.B
          ? {
              classId: entry.classes_ab_weeks.B.classId,
              subjectId: entry.classes_ab_weeks.B.subjectId,
            }
          : null;

      const abWeeks =
        aBWeekA || aBWeekB
          ? {
              ...(aBWeekA && { A: aBWeekA }),
              ...(aBWeekB && { B: aBWeekB }),
            }
          : null;

      return {
        day: entry.day,
        day_name: entry.day_name,
        hour: entry.hour,
        classId: entry.classId,
        subjectId: entry.subjectId,
        ...(abWeeks && {
          classes_ab_weeks: abWeeks,
        }),
      };
    });

    // delete single item and update
    if (clearEntry && !this.state.ab_weeks) {
      const newTimeTable = {
        ...this.props.timetable,
        entries: timeTableEntries,
      };
      this.props.upsertTimetable(newTimeTable, this.state.schoolyear, false);
      this.closeModal();
      return;
    }

    // new timetable entry
    const newEntryObject = {
      day: this.state.day,
      day_name: this.state.modalDay,
      hour: this.state.hour,
      ...(classEntry && {
        classId: classEntry._id,
        subjectId: subjectEntry._id,
      }),
    };

    // if Ab weeks - add timetable ab weeks entries / delete
    const ABEntryObject = this.state.ab_weeks
      ? this.entriesABWeeks(
          newEntryObject,
          item.week_type,
          this.props.timetable.entries,
          clearEntry,
        )
      : {};

    const newTimetable = this.state.ab_weeks
      ? {
          ...this.props.timetable,
          entries: ABEntryObject
            ? [...timeTableEntries, ABEntryObject]
            : [...timeTableEntries],
        }
      : {
          ...this.props.timetable,
          entries: [...timeTableEntries, newEntryObject],
        };
    this.props.upsertTimetable(newTimetable, this.state.schoolyear, false);
    this.closeModal();
  }

  /**
   * open an modal layer with clicked hour and date informations
   * @param hour
   * @param day
   * @param class_id
   * @param subject_id
   */
  openModal(
    hour,
    day,
    class_id = false,
    subject_id = false,
    class_id_b = false,
    subject_id_b = false,
  ) {
    this.setState({
      modalIsOpen: true,
      modalDay: TimeTableDayLabels[day],
      modalHour: hour + 1,
      hour,
      day,
      preselect_class_id: class_id,
      preselect_subject_id: subject_id,
      preselect_class_id_b: class_id_b,
      preselect_subject_id_b: subject_id_b,
    });
  }

  /**
   * not yet implemented
   */
  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  toggleABSettingsEdit(status) {
    if (status === 0) {
      // abbrechen - evtl änderungen reseten
      this.setState({
        ab_settings_edit: status,
        ab_weeks_change_state: this.state.ab_weeks, // <- reset to default
      });
    } else {
      // open
      this.setState({
        ab_settings_edit: status,
      });
    }
  }

  changeABSettingsHandler(val) {
    this.setState({
      ab_weeks_change_state: val,
    });
  }

  /**
   * change the values (on/off) for ab weeks
   * @param abWeeksTurnedOn
   */
  changeABSettings(abWeeksTurnedOn) {
    if (abWeeksTurnedOn === this.state.ab_weeks) {
      // do nothing same value
      return;
    }
    const currentYear = this.props.schoolyear;
    const active = Number(abWeeksTurnedOn);
    const newSettings = immutableUpdate(this.props.settings, (draft) => {
      const defaultValue = { active, holiday_break: false, entries: [] };
      draft.schoolyear = currentYear;

      if (!draft.settings.ab_weeks) {
        // nothing set set default value for current year
        draft.settings.ab_weeks = { [currentYear]: defaultValue };
        return;
      }
      if (!draft.settings.ab_weeks[currentYear]) {
        // other years set, set default value for current year
        draft.settings.ab_weeks[currentYear] = defaultValue;
        return;
      }
      // current year exists, set active value
      draft.settings.ab_weeks[currentYear].active = active;
    });

    if (!abWeeksTurnedOn) {
      // cleanup Ab from class list and timetable - delete b entries
      this.cleanUpABProperties();
    } else {
      this.setUpABProperties();
    }
    this.props.updateSettings(newSettings);

    this.setState({
      ab_settings_edit: false,
      ab_weeks: abWeeksTurnedOn,
    });
  }

  hasABWeeksSettings() {
    const { props } = this;
    if (props.settings && props.settings.settings) {
      // settings exists, check if have ab active for the current year
      const year = props.schoolyear;
      const { settings } = props.settings;
      if (Number(settings.ab_weeks?.[year]?.active) === 1) {
        return true;
      }
    }
    return false;
  }

  /**
   * close modal layer and reset presentation data
   */
  closeModal() {
    this.setState({
      modalIsOpen: false,
      modalDay: "",
      modalHour: "",
      hour: "",
      day: null,
      preselect_class_id: false,
      preselect_subject_id: false,
      preselect_class_id_b: false,
      preselect_subject_id_b: false,
    });
  }

  /**
   * add an time table hour label to the list
   * @param hours
   */
  addTimeTableLabel(hours) {
    let cnt = this.state.timeTableLabelCnt;
    if (hours >= cnt) {
      cnt += 1;
      this.setState({
        timeTableLabelCnt: cnt,
      });
    }
  }

  /**
   * bei der Umstellung wieder alles auf einen wert
   * nur a wochen zu normalen machen
   */
  cleanUpABProperties() {
    // alle a class elemente ermitteln und bei entries wieder rein.
    const newEntries = this.props.timetable.entries
      .filter((elem) => elem.classes_ab_weeks && elem.classes_ab_weeks.A)
      .map((elem) => ({
        day: elem.day,
        day_name: elem.day_name,
        hour: elem.hour,
        classId: elem.classes_ab_weeks.A.classId,
        subjectId: elem.classes_ab_weeks.A.subjectId,
      }));
    this.props.upsertTimetable(
      { ...this.props.timetable, entries: newEntries },
      this.state.schoolyear,
      false,
    );
  }

  /**
   * @param obj
   * @param week_type
   * @param entries
   * @param clearItem
   * @returns {*}
   */
  entriesABWeeks(obj, week_type, entries, clearItem) {
    const other_weektype = week_type === "A" ? "B" : "A";
    const exists_idx = entries.findIndex(
      (el) =>
        Number(el.day) === Number(obj.day) &&
        Number(el.hour) === Number(obj.hour),
    );

    const abMetaAttributes = {
      classId: obj.classId,
      subjectId: obj.subjectId,
    };

    if (exists_idx !== -1 && entries[exists_idx].classes_ab_weeks) {
      const exists_entry = entries[exists_idx].classes_ab_weeks;
      // delete ab week item
      if (clearItem) {
        if (exists_entry[week_type]) {
          const clear_class_id = exists_entry[week_type].classId;
          const clear_subject_id = exists_entry[week_type].subjectId;

          const existsEntryAB = {
            ...exists_entry,
          };
          delete existsEntryAB[week_type];

          // prüfen ob der B Eintrag das gleiche (Fach, Klasse) ist, wenn ja mit löschen.
          if (week_type === "A") {
            if (
              existsEntryAB.B &&
              existsEntryAB.B.classId === clear_class_id &&
              existsEntryAB.B.subjectId === clear_subject_id &&
              existsEntryAB.B.week_clone
            ) {
              delete existsEntryAB.B;
            }
          }
          return existsEntryAB.A || existsEntryAB.B
            ? {
                ...obj,
                classes_ab_weeks: existsEntryAB,
              }
            : false;
        }
      } else {
        // update
        const existsEntryAB = {
          ...exists_entry,
        };
        existsEntryAB[week_type] = {
          ...abMetaAttributes,
        };

        // wenn a geklickt und  B nicht vorhanden -> duplizieren
        // wenn a geklickt und b nicht vorhanden oder b ein clone von a ist -> duplizieren
        if (
          (!existsEntryAB[other_weektype] && other_weektype === "B") ||
          (existsEntryAB[other_weektype] &&
            other_weektype === "B" &&
            existsEntryAB[other_weektype].week_clone)
        ) {
          existsEntryAB[other_weektype] = {
            ...abMetaAttributes,
          };
          // obj.class;
          existsEntryAB[other_weektype].week_clone = true;
        }
        return {
          ...obj,
          ...abMetaAttributes,
          classes_ab_weeks: existsEntryAB,
        };
      }

      // neu hinzufügen
    } else {
      const classes_ab_weeks = {};
      classes_ab_weeks[week_type] = {
        ...abMetaAttributes,
      };

      // nur wenn A geklickt wurde und noch nichts anderes existiert
      if (other_weektype === "B") {
        if (!classes_ab_weeks[other_weektype]) {
          classes_ab_weeks[other_weektype] = {};
        }

        classes_ab_weeks[other_weektype] = {
          ...abMetaAttributes,
        };

        // obj.class;
        classes_ab_weeks[other_weektype].week_clone = true;
      }
      return {
        ...obj,
        ...abMetaAttributes,
        classes_ab_weeks,
      };
    }
    const { class: _, ...objWithoutClass } = obj;
    return objWithoutClass;
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    // this.props.updateSubjects(this.props.subjects.filtered)
    this.props.nextSection();
  }

  prevSection() {
    this.props.prevSection();
  }

  addModalHoverClass() {
    this.setState({
      modalHoverClass: "hover_item",
    });
  }

  removeModalHoverClass() {
    this.setState({
      modalHoverClass: "",
    });
  }

  findMaxHourFromList(day) {
    const def_cnt = this.state.timeTableDefCnt;
    const list =
      this.props.timetable && this.props.timetable.entries.length > 0
        ? this.props.timetable.entries
        : [];
    let res = 0;

    if (typeof day !== "undefined") {
      const tt = this.props.timetable.entries.filter(
        (entry) => Number(entry.day) === day,
      );
      if (tt.length > 0) {
        res = Math.max(...tt.map((o) => o.hour));
      }
    } else {
      res = Math.max(...list.map((o) => o.hour));
    }

    if (def_cnt > res) {
      res = def_cnt;
    }
    return res;
  }

  resetData() {
    this.props.clearAll();
  }

  /**
   *
   * @param value
   */
  changeSaturday(value) {
    const obj = { ...this.props.timetable };
    obj.saturday = Number(value) === 1;
    this.props.upsertTimetableSaturday(obj, this.state.schoolyear, false);
  }

  /**
   * create the columns from monday to saturday
   * @return {Array}
   */
  renderColumn(saturday_css_class) {
    const cnt = this.state.timeTableLabelCnt;
    const label_count_list = this.findMaxHourFromList();

    let label_count = 0;
    if (label_count_list > cnt) {
      label_count = label_count_list;
    } else {
      label_count = cnt;
    }

    const column_items = [];
    let tt = {};

    column_items.push(
      <TimeTableColumnLabel cnt={label_count} key="tt-column-label-0" />,
    ); // first column are the labels of hours

    for (let i = 0; i <= 4; i += 1) {
      // check if an timetable entry exists for current day.
      tt = this.props.timetable.entries.filter(
        (entry) => Number(entry.day) === i,
      );

      column_items.push(
        <TimeTableColumns
          ab_weeks={this.state.ab_weeks}
          timetable={tt}
          addTimeTableLabel={this.addTimeTableLabel}
          day={i}
          openModal={this.openModal}
          show_saturday=""
          cnt=""
          key={`tt-column-${i}`}
        />,
      );
    }
    // push saturday

    tt = this.props.timetable.entries.filter(
      (entry) => Number(entry.day) === 5,
    );
    const rcnt = this.findMaxHourFromList(5);
    column_items.push(
      <TimeTableColumns
        ab_weeks={this.state.ab_weeks}
        timetable={tt}
        addTimeTableLabel={this.addTimeTableLabel}
        day={5}
        openModal={this.openModal}
        show_saturday={saturday_css_class}
        cnt={rcnt}
        key="tt-column-5"
      />,
    );
    return column_items;
  }

  render() {
    const nextenabled = this.props.classes.length > 0;
    // saturday stuff
    const isSaturdayValue =
      this.props.timetable && this.props.timetable.saturday ? 0 : 1;
    const defaultCheckedSaturday = !!(
      this.props.timetable && this.props.timetable.saturday
    );
    let saturday_css_class = "";
    let hasSaturdayClass = "with_saturday";

    if (!defaultCheckedSaturday) {
      saturday_css_class = "hidden";
      hasSaturdayClass = "";
    }

    // last modal row
    const last_modal = {
      title: "",
      subjects: [{ title: "Leerstunde", color: "#F2F2F2" }],
    };
    const hover_css = this.state.modalHoverClass;

    let ab_mode_css = "";
    let ab_edit_css = "";
    let ab_active_css = "";
    let ab_inactive_css = "";

    const { ab_settings_edit, ab_weeks, ab_weeks_change_state } = this.state;

    const canSaveTimetableMode = ab_weeks !== ab_weeks_change_state;

    if (ab_weeks_change_state) {
      ab_inactive_css = "";
      ab_active_css = "active";
    } else {
      ab_inactive_css = "active";
      ab_active_css = "";
    }

    if (ab_settings_edit) {
      ab_mode_css = "hide";
      ab_edit_css = "show";
    } else {
      ab_mode_css = "show";
      ab_edit_css = "hide";
    }

    return (
      <div id="timetableView" className={hasSaturdayClass}>
        <div className="setting-ab-weeks">
          <div className={`info-wrapper ${ab_mode_css}`}>
            <span className="mode">Modus</span>
            <span className="info">
              {!ab_weeks ? (
                <span>gleicher Stundenplan für alle Wochen</span>
              ) : (
                <span>A/B Wochen</span>
              )}
            </span>
            <span className="edit">
              <a
                onClick={() => {
                  this.toggleABSettingsEdit(1);
                }}
              >
                <img src="/assets/images/edit-icon.png" className="form-icon" />
              </a>
            </span>
          </div>
          <div className={`edit-wrapper ${ab_edit_css}`}>
            <div className="mode">Modus</div>

            <span className={`item ${ab_inactive_css}`}>
              <input
                type="radio"
                name="ab_setting"
                id="input-equal-weeks"
                value="0"
                checked={!ab_weeks_change_state}
                onChange={() => {
                  this.changeABSettingsHandler(false);
                }}
              />
              <label htmlFor="input-equal-weeks">
                gleicher Stundenplan für alle Wochen
              </label>
            </span>

            <span className={`item ${ab_active_css}`}>
              <input
                type="radio"
                name="ab_setting"
                id="input-ab-weeks"
                value="1"
                checked={ab_weeks_change_state}
                onChange={() => {
                  this.changeABSettingsHandler(true);
                }}
              />
              <label htmlFor="input-ab-weeks">A/B Wochen</label>
            </span>

            <div className="btn-wrapper">
              {!canSaveTimetableMode ? (
                <button className="btn btn-dark" disabled>
                  speichern
                </button>
              ) : (
                <button
                  className="btn btn-step active"
                  onClick={() => {
                    this.changeABSettings(ab_weeks_change_state);
                  }}
                >
                  speichern
                </button>
              )}
              &nbsp; &nbsp; &nbsp;
              <button
                className="btn btn-dark"
                onClick={() => {
                  this.toggleABSettingsEdit(0);
                }}
              >
                abbrechen
              </button>
            </div>
          </div>
        </div>

        <div className="modal-wrapper-out" id="modal-wrapper-out">
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={ModalStyles}
            ariaHideApp={false}
            contentLabel="Stundenplan"
          >
            <div className={`modal-wrapper ${hover_css}`}>
              <div className="modal-header timetable">
                <span className="day">{this.state.modalDay},</span>
                <span className="hour">{this.state.modalHour}. Stunde</span>
              </div>

              {this.state.ab_weeks ? (
                <Tabs className="tab-main-wrapper">
                  <div className="tab-links">
                    <TabLink to="tab_a_week">A Woche</TabLink>
                    <TabLink to="tab_b_week">
                      B Woche (falls abweichend)
                    </TabLink>
                  </div>

                  <TabContent for="tab_a_week">
                    <div className="closer">
                      <a
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        <img src="/assets/images/close_modal.png" />
                      </a>
                    </div>

                    {this.props.classes.map((p, i) => (
                      <ClassesSubjectRow
                        {...p}
                        key={`cs-arow-${i}`}
                        addModalHoverClass={this.addModalHoverClass}
                        removeModalHoverClass={this.removeModalHoverClass}
                        handleItemClick={this.updateTimeTableItem}
                        nr={i}
                        week_type="A"
                        preselect_class={this.state.preselect_class_id}
                        preselect_subject={this.state.preselect_subject_id}
                      />
                    ))}
                    <ClassesSubjectRow
                      {...last_modal}
                      key="cs-arow-9999"
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.updateTimeTableItem}
                      nr="9999"
                      week_type="A"
                      preselect_class={this.state.preselect_class_id}
                      preselect_subject={this.state.preselect_subject_id}
                    />
                  </TabContent>
                  <TabContent for="tab_b_week">
                    <div className="closer">
                      <a
                        onClick={() => {
                          this.closeModal();
                        }}
                      >
                        <img src="/assets/images/close_modal.png" />
                      </a>
                    </div>

                    {this.props.classes.map((p, i) => (
                      <ClassesSubjectRow
                        {...p}
                        key={`cs-brow-${i}`}
                        addModalHoverClass={this.addModalHoverClass}
                        removeModalHoverClass={this.removeModalHoverClass}
                        handleItemClick={this.updateTimeTableItem}
                        nr={i}
                        week_type="B"
                        preselect_class={this.state.preselect_class_id_b}
                        preselect_subject={this.state.preselect_subject_id_b}
                      />
                    ))}
                    <ClassesSubjectRow
                      {...last_modal}
                      key="cs-brow-9999"
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.updateTimeTableItem}
                      nr="9999"
                      week_type="B"
                      preselect_class={this.state.preselect_class_id_b}
                      preselect_subject={this.state.preselect_subject_id_b}
                    />
                  </TabContent>
                </Tabs>
              ) : (
                <div className="defaul-tt-modal">
                  {this.props.classes.map((p, i) => (
                    <ClassesSubjectRow
                      {...p}
                      key={`cs-arow-${i}`}
                      addModalHoverClass={this.addModalHoverClass}
                      removeModalHoverClass={this.removeModalHoverClass}
                      handleItemClick={this.updateTimeTableItem}
                      nr={i}
                      week_type="A"
                      preselect_class={this.state.preselect_class_id}
                      preselect_subject={this.state.preselect_subject_id}
                    />
                  ))}
                  <ClassesSubjectRow
                    {...last_modal}
                    key="cs-arow-9999"
                    addModalHoverClass={this.addModalHoverClass}
                    removeModalHoverClass={this.removeModalHoverClass}
                    handleItemClick={this.updateTimeTableItem}
                    nr="9999"
                    week_type="A"
                    preselect_class={this.state.preselect_class_id}
                    preselect_subject={this.state.preselect_subject_id}
                  />
                </div>
              )}
            </div>
          </Modal>
        </div>

        <div className="tt-day-wrapper">
          <div className="day-label first" />
          <div className="day-label">
            Montag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Dienstag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Mittwoch
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Donnerstag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className="day-label">
            Freitag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div className={`day-label ${saturday_css_class}`}>
            Samstag
            {this.state.ab_weeks && (
              <div className="ab_week_info">
                <span className="left">A</span>
                <span className="right">B</span>
              </div>
            )}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        <div className="tt-wrapper">
          {this.renderColumn(saturday_css_class)}
          <div style={{ clear: "both" }} />
        </div>

        <div className="add_column">
          Samstagsunterricht
          <input
            onChange={(e) => {
              this.changeSaturday(e.target.value);
            }}
            defaultChecked={defaultCheckedSaturday}
            type="checkbox"
            value={isSaturdayValue}
          />
        </div>
        <div className="line" />

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="back">
              <a href="#" onClick={() => this.prevSection()}>
                Zurück zu Klassen
              </a>
            </div>
            <div className="next">
              <button
                disabled={!nextenabled}
                className="btn btn-step"
                onClick={() => this.nextSection()}
              >
                Schule und Bundesland
              </button>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTimetableForm);
