import React from "react";
import { connect } from "react-redux";
import { uniqueId } from "../../sequence_calendar/Utils";
import CheckBox from "./CheckBox";
import ClassItem from "./Class";
import Auth from "../../../services/Auth";
import * as ACTIONS from "../../../../features/classes/actions";
import * as TTACTIONS from "../../../../features/timetable/actions";
import { sortClassesAlphabetically } from "../../../utils/assortments";
import { selectSettings } from "../../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../../features/current-schoolyear/current-schoolyear-slice";

const mapStateToProps = (state) => ({
  schoolyear: selectCurrentSchoolyear(state),
  subjects: state.subjects,
  classes: sortClassesAlphabetically(state.classes),
  timetable: state.timetable,
  settings: selectSettings(state),
});

const mapDispatchToProps = {
  addClassrooms: ACTIONS.add_class,
  addClassLocal: ACTIONS.addClassLocal,
  removeClassLocal: ACTIONS.removeClassLocal,
  addClassList: ACTIONS.addClassList,
  fetchClassesList: ACTIONS.fetchClassesList,
  removeClassFromList: ACTIONS.removeClassFromList,
  updateClassList: ACTIONS.updateClassListItem,
  fetchTimetable: TTACTIONS.fetchTimetable,
};

class AddClassroomsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subjects: [],
      clearChecked: false,
      ab_weeks: false,
    };
    this.addClassroom = this.addClassroom.bind(this);
    this.setClassroomId = this.setClassroomId.bind(this);

    // new methods
    this.addSubjectToClass = this.addSubjectToClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.removeFromList = this.removeFromList.bind(this);
    this.prevSection = this.prevSection.bind(this);
    this.lastSection = this.lastSection.bind(this);
    this.updateClassList = this.updateClassList.bind(this);
    this.hasABWeeksSettings = this.hasABWeeksSettings.bind(this);
  }

  componentDidMount() {
    const hasABWeeksSettings = this.hasABWeeksSettings();
    this.setState({
      ab_weeks: hasABWeeksSettings,
    });

    if (this.props.fetchData) {
      // console.log('load via did mount');
      this.props.fetchClassesList(Auth.getUserId(), this.props.schoolyear);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.classes.length !== this.props.classes.length) {
      this.props.fetchTimetable(Auth.getUserId(), this.props.schoolyear);
    }
  }

  handleEnter(e) {
    const enabled = this.state.title !== "" && this.state.subjects.length > 0;
    if (enabled && e.key === "Enter") {
      this.addClassroom();
      e.target.blur();
    }
  }

  /**
   * set the class title / name
   */
  setClassesTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  setClassroomId(e) {
    this.props.setClassroomId(e.target.value);
  }

  addClassroom() {
    if (this.state.title !== "") {
      const class_obj = {
        user_id: Auth.getUserId(),
        title: this.state.title,
        subjects: this.state.subjects,
        schoolyear: this.props.schoolyear,
        schooldays: [],
      };

      this.props.addClassList(class_obj);

      // reset for next class object
      this.setState({
        title: "",
        subjects: [],
        clearChecked: true,
      });
    }
  }

  /**
   * add subject ids to classes
   * @param type
   * @param value
   */
  addSubjectToClass(type, value) {
    let { subjects } = this.state;
    const parsedValue = JSON.parse(value);

    if (type === "add") {
      subjects.push(parsedValue);
    } else {
      const list = subjects.filter((p) => p._id !== parsedValue._id);
      subjects = list;
    }
    this.setState({
      subjects,
      clearChecked: false,
    });
  }

  /**
   * remove a subject from class
   */
  removeClass(idx) {
    this.props.removeClassLocal(idx);
    this.props.fetchTimetable(Auth.getUserId(), this.props.schoolyear);
  }

  removeFromList(id) {
    this.props.removeClassFromList(id);
    this.props.fetchTimetable(Auth.getUserId(), this.props.schoolyear);
  }

  updateClassList(obj) {
    obj.schoolyear = this.props.schoolyear;
    obj.user_id = Auth.getUserId();
    this.props.updateClassList(obj, this.props.schoolyear);
    this.props.fetchTimetable(Auth.getUserId(), this.props.schoolyear);
  }

  hasABWeeksSettings() {
    const { props } = this;
    if (props.settings && props.settings.settings) {
      // settings exists, check if have ab active for the current year
      const { settings } = props.settings;
      const year = props.schoolyear;
      if (Number(settings.ab_weeks?.[year]?.active) === 1) {
        return true;
      }
    }
    return false;
  }

  /**
   * fill storage in register and go away
   */
  nextSection() {
    // this.props.updateSubjects(this.props.subjects.filtered)
    this.props.nextSection(this.props.classes);
  }

  /**
   * fill storage in register and go away
   */
  lastSection() {
    // this.props.updateSubjects(this.props.subjects.filtered)
    this.props.lastSection();
  }

  prevSection() {
    this.props.prevSection();
  }

  render() {
    const enabled = this.state.title !== "" && this.state.subjects.length > 0;
    const nextenabled = this.props.classes.length > 0;

    const subject_list =
      this.props.subjects.raw && this.props.subjects.raw.length > 0
        ? this.props.subjects.raw
        : [];

    const class_list = this.props.classes;
    const show_jumper_school = this.props.classes.length === 0;

    const { ab_weeks } = this.state;

    return (
      <div id="classrooms" className="">
        <div className="classes_list">
          {class_list.map((item) => {
            const unique = uniqueId();
            return (
              <ClassItem
                key={`idx-${unique}`}
                title={item.title}
                subjects={item.subjects}
                subject_list={subject_list}
                id={item._id}
                ab_weeks={ab_weeks}
                updateClassList={this.updateClassList}
                removeFromList={this.removeFromList}
                timetable={this.props.timetable}
                updateTimeTable={this.updateTimeTable}
                item={item}
              />
            );
          })}
        </div>

        <div className="row">
          <div className="classes_item_container">
            <div className="fields">
              <div className="field_input">
                <label>Klasse</label>
                <input
                  placeholder="z.B. Klasse 6b"
                  className="inputfield"
                  value={this.state.title}
                  onChange={(e) => {
                    this.setClassesTitle(e);
                  }}
                  onKeyDown={(e) => this.handleEnter(e)}
                />
              </div>
            </div>
            <div className="subjects">
              <label>
                Fächer{" "}
                <span className="info">
                  (die du in dieser Klasse unterrichtest)
                </span>
              </label>
              <div className="subject_items">
                {subject_list.map((item, idx) => {
                  const key = `select-${idx}`;
                  return (
                    <CheckBox
                      key={idx}
                      cssid={key}
                      item={item}
                      addSubjectToClass={this.addSubjectToClass}
                      value={item}
                      clearChecked={this.state.clearChecked}
                      onKeyDown={(e) => this.handleEnter(e)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="btn_wrapper">
              <button
                disabled={!enabled}
                className="btn btn-dark"
                onClick={this.addClassroom}
              >
                anlegen
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="btn-wrapper steps-btns">
            <div className="back">
              <a href="#" onClick={() => this.prevSection()}>
                Zurück zu Fächer
              </a>
            </div>

            {show_jumper_school && (
              <div className="next">
                <button className="btn btn-dark" onClick={this.lastSection}>
                  überspringen zu Schule und Rechtliches
                </button>
              </div>
            )}

            {!show_jumper_school && (
              <div className="next">
                <button
                  disabled={!nextenabled}
                  className="btn btn-step"
                  onClick={() => this.nextSection()}
                >
                  Weiter zu Stundenplan
                </button>
              </div>
            )}

            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClassroomsForm);
